import React from 'react';
import { RichText, Elements } from 'prismic-reactjs';
import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql';

// styles
import { Container, NormalText } from '@styles';
import {
  PrivacySection,
  StyledH4,
  StyledUL,
  StyledLI,
  StyledLink,
} from './styles';

const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};
const sectionSerializer = function (type, element, content, children, key) {
  var props = {};
  switch (type) {
    case Elements.heading4:
      return React.createElement(
        StyledH4,
        propsWithUniqueKey(props, key),
        children
      );
    case Elements.paragraph:
      return React.createElement(
        NormalText,
        propsWithUniqueKey(props, key),
        children
      );
    case Elements.hyperlink:
      const targetAttr = element.data.target
        ? { target: element.data.target }
        : {};
      const relAttr = element.data.target ? { rel: 'noopener' } : {};
      props = Object.assign(
        {
          href: element.data.url || linkResolver(element.data),
        },
        targetAttr,
        relAttr
      );
      return React.createElement(
        StyledLink,
        propsWithUniqueKey(props, key),
        children
      );
    case Elements.label:
      return React.createElement(
        StyledUL,
        propsWithUniqueKey(props, key),
        children
      );
    case Elements.listItem:
      return React.createElement(
        StyledLI,
        propsWithUniqueKey(props, key),
        children
      );
    default:
      return null;
  }
};

const Privacy = ({ data }) => {
  return (
    <PrivacySection>
      <Container normal>
        <RichText render={data.text} htmlSerializer={sectionSerializer} />
      </Container>
    </PrivacySection>
  );
};

export default Privacy;
