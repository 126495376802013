import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import BreadCrumb from '@components/breadcrumb';
import Privacy from '@views/privacy';

const RenderBody = ({ doc }) => {
  const privacy = doc.policy[0];

  return (
    <>
      <SEO title="Privacy Policy" />
      <BreadCrumb name="Privacy" />
      <Privacy data={privacy} />
    </>
  );
};

export default ({ data }) => {
  const doc = data.prismic.allPrivacys.edges.slice(0, 1).pop();

  if (!doc) return null;
  return <RenderBody doc={doc.node} />;
};

export const query = graphql`
  {
    prismic {
      allPrivacys {
        edges {
          node {
            policy {
              text
            }
          }
        }
      }
    }
  }
`;
